
/*   -=========== Catalogue ===========-   */

.catalogue-page--caption {
  text-align: center;
  position: relative;
  color: #000;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: #828282;
    position: absolute;
    left: 0; top: 60%;
    transform: translateY(-50%);
  }

  > .h1 {
    display: inline-block;
    background: $white;
    padding: 0 15px;
    position: relative;

    h1 {
      display: inline;
    }
  }
}

.catalogue-page {
  &__subtitle {
    color: #4f4f4f;
    font-size: 2.8rem;
    text-align center;
    margin-bottom: 1.5em;

    @media $notebook {
      font-size: 2.3rem;
    }

    @media $tablet {
      font-size: 1.8rem;
    }

    @media $mobile {
      font-size: 1.3rem;
    }
  }
}

.paint-companys-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 200px);
  justify-content: space-between;
  margin-bottom: 35px;

  @media $handheld {
    grid-template-columns: repeat(3, 200px);
    gap: 10px;
  }

  @media $mobile {
    grid-template-columns: repeat(2, 1fr);
  }
}

.paint-companys__colymn {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.paint-company {
  padding: 10px 15px;
  text-decoration: none;
  color: #828282;
  border: 1px solid transparent;
  border-radius: 30px;
  transition: all 0.25s ease-out;

  @media $mobile {
    font-size: 15px;
  }

  &:hover {
    border-color: $blue;
    color: #000;
  }
}

.paint-colors,
.paint-companys-wrapper {
  max-width: 1370px;
  margin: 0 auto 35px;

  @media $notebook-and-less {
    margin: 0 20px 35px;
  }
}

.paint-colors {
  @media $small-mobile {
    margin: 0 35px 25px;
  }
}

.paint-colors__caption {
  text-align: center;
  color: #000;
  margin-bottom: 35px;
}

.paint-colors__list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 5px;
  margin-bottom: 45px;

  @media $small-mobile {
    margin-bottom: 25px;
  }
}

.paint-colors__list-item {
  width: 56px;
  height: 34px;
  box-sizing: border-box;
  border-radius: 25px;
  transition: all 0.25s ease-out;
  border: 3px solid $white;

  &:hover,
  &.selected {
    box-shadow: rgb(130, 130, 130) 0px 0px 0px 1px;
  }
}

@media $not-mobile {
  .paint-colors__list-item:nth-child(132) ~ .paint-colors__list-item {
    opacity: 0;
    height: 0;
    transition: all 0.1s ease;
    transform: scaleY(0);
    transform-origin: 0 0;
    position: absolute;
  }

  .paint-colors.is-expanded .paint-colors__list-item:nth-child(132) ~ .paint-colors__list-item {
    opacity: 1;
    height: 34px;
    transition: all 0.1s ease;
    transform: scaleY(1);
    transform-origin: 0 0;
    position: unset;
  } 
}

@media $mobile {
  .paint-colors__list-item:nth-child(50) ~ .paint-colors__list-item {
    opacity: 0;
    height: 0;
    transition: all 0.1s ease;
    transform: scaleY(0);
    transform-origin: 0 0;
    position: absolute;
  }

  .paint-colors.is-expanded .paint-colors__list-item:nth-child(50) ~ .paint-colors__list-item {
    opacity: 1;
    height: 34px;
    transition: all 0.1s ease;
    transform: scaleY(1);
    transform-origin: 0 0;
    position: unset;
  } 
}

.paint-colors__more {
  margin: 0 auto;
  padding: 10px 30px;
  display: block;
  text-decoration: none;
  border: 1px solid #D9DFE7;
  border-radius: 30px;
  max-width: 155px;
  width: 100%;
  box-sizing: border-box;
  color: #828282;
  transition: all 0.25s ease-out;

  &:hover {
    border-color: #000;
  }
}

.catalogue-page--content {
  padding-bottom: 50px;
  position: relative;

  @media $not-small-mobile {
    overflow: hidden;
  }
}

.catalogue-page--text {
  font-size: 1.4rem;
  margin-bottom: 35px;
  max-width: 1200px;

  @media $small-mobile {
    padding-left: 10px;
    padding-right: 10px;

    &:not(.is-expanded) {
      margin-bottom: 0;

      p + p {
        display: none;
      }
    }
  }
}

.catalogue-page--text-bottom--preview {
  font-size: 11pt;
  font-family: Arial;
  color: rgb(0, 0, 0);
  background-color: transparent;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-variant-alternates: normal;
  vertical-align: baseline;
}

.catalogue-page--text-bottom {
  margin-top: 3em;
}

.catalogue-page--text-toggle {
  text-decoration: underline;
  color: #828282;
  padding-left: 10px;
  font-size: 1.4rem;
  margin-top: 5px;
  margin-bottom: 15px;

  .is-expanded + & {
    display: none;
  }
}

.filter-and-sorting {
  display: flex;
  flex-grow: 1;
  gap: 10px
  align-items: center;

  .filter-wrapper {
    flex-grow: 1;
    position: relative;

    .mobile-filter-parameters-count {
      display: none;
      opacity: 0;
      position: absolute;
      top: 6px;
      left: 43px;
      width: 18px;
      height: 18px;
      border-radius: 10px;
      background-color: #FF867E;
      color: #fff;
      font-size: 14px;
      line-height: 18px;
      text-align: center;

      @media $handheld {
        display: block;
      }    
    }
  
    @media $mobile {
      flex-grow: 0;
      height: 45px;
    }
  }

  @media $mobile {
    max-width: 267px;
    height: 45px;
    margin-left: 8px;
    float: left;
    flex-grow: 1;
    align-items: center;
    justify-content: center
  }

  .sorting-container-btn {
    width: 82px;
    height: 45px;
    border: 1px solid #97CDFA;
    border-radius: 5px;
    margin-top: 2px;
    cursor: pointer;

    img {
      display: block;
      width: 60px;
      height: 35px;
      margin: auto;
      margin-top: 5px;
    }
  }

  .sorting-container-filter {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1100;
    background-color: #00000090;

    .sorting-container-wrapper {
      width: calc(100% - 50px) !important;
      height: 210px;
      margin: calc((100vh - 264px) / 2) auto;
      padding: 20px 0 34px;
      background-color: #fff;
      border-radius: 10px;

      .sorting-container-header {
        width: min-content;
        font-size: 24px;
        line-height: 26px;
        margin: 11px 0 0 25px;
        float: left;
      }

      .sorting-container-close {
        @extend .btn;
        width: 127px;
        height: 31px;
        padding: 0 1px 0 8px;
        margin: 10px 25px 0 0;
        float: right;
        border-radius: 16px;
        background: #97CDFA;
        color: $white;
        text-align: left;
        font-size: 20px;
        line-height: 31px;
      
        &:after {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          margin: 8px 10px 0 0;
          background: url(/i/close-no-border.svg) no-repeat;
          background-size: contain;
          float: right;
        }
      }

      .sorting-container-values {
        position: relative;
        width: 100%;
        height: 150px;
        margin-top: 60px;
        color: #000;

        .sorting-container-values--item {
          width: 100%;
          height: 50px;
          font-size: 20px;
          line-height: 50px;
          text-align: center;
          user-select: none;
          cursor: pointer;

          span {
            position: relative;
            z-index: 1201;
          }
        }

        .sorting-container-values--selected {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 50px;
          background-color: #edf7ff;
          z-index: 1200;
          transition: .3s;

          img {
            display: block;
            width: 21px;
            height: 17px;
            margin: 16px 0 0 55px;
          }
        }
      }
    }
  }
}

.catalogue-page--sorting {
  //text-align: right;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  flex-wrap: wrap
  justify-content: center;
  gap: 10px;

  .sharing-block-btn {
    width: 82px;
    height: 45px;
    border: 1px solid #97CDFA;
    border-radius: 5px;
    float: left;
    cursor: pointer;

    img {
      display: block;
      width: 25px;
      height: 35px;
      margin: auto;
      margin-top: 5px;
    }
  }

  .sharing-block-wrapper-filter {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1100;
    background-color: #00000090;

    .sharing-block-wrapper-mobile {
      width: calc(100% - 60px) !important;
      height: 230px;
      margin: calc((100vh - 284px) / 2) auto;
      padding: 20px 25px 34px;
      background-color: #fff;
      border-radius: 10px;

      .sharing-block-header {
        width: 250px;
        font-size: 24px;
        line-height: 26px;
        margin-top: 11px;
        float: left;
      }

      .sharing-block-close {
        display: block;
        width: 50px;
        height: 50px;
        float: right;
      }

      .sharing-block-copy {
        display: block !important;
        width: 100%;
        height: 45px;
        margin-top: 75px;
        background-color: #9CCCFA;
        text-align: center;
        font-size: 20px;
        line-height: 45px;
        color: #fff;
        box-shadow: 0 1px 8px #00000020;
        border: none !important;
        border-radius: 0 !important;
      }

      .sharing-block-text {
        margin-top: 24px;
        font-size: 20px;
        line-height: 26px;
        color: #787878;
        text-align: left;
      }

      .sharing-block-social-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 50px;
        width: 100%;
        height: 54px;
        margin-top: 12px;
        border: 1px solid #97CDFA;
        border-radius: 10px;

        & a:before {
          width: 30px !important;
          height: 30px !important;
        }
      }
    }
  }

  .sharing-block-wrapper {
    display: flex;
    align-items: center;

    @media $desktop {
      flex-grow: 1;
    }
  }
  .button--copy-link {
    //position: absolute; left: 0; right: 0;

    @media $landscape-mobile {
      display none;
    }
  }

  .select-container + .select-container {
    //margin-left: 12px;

    @media $small-mobile {
      margin-bottom: 0; //margin-top: 12px; padding-bottom: 15px;
    }
  }

  @media $small-mobile {
    /* position: absolute;
    left: 0; top: 0; */
    text-align: center;
    align-items: baseline;

    &:not(.catalogue-page--sorting_wide) .select-container:not(.sorting--select) {
      display: none;
    }
  }

  @media $mobile {
    display: block;
    width: 356px;
    height: 47px;
    margin: auto;
  }
}

.catalogue-page--pager {
  text-align: center;

  @media $small-mobile {
    overflow: hidden;
  }
}

@media $handheld {
  .left-aside.left-aside_filter-aside {
    display: none;
    position: fixed;
    left: 0 !important; top: 100px !important;
    z-index: 1001;
    background: $white;
    box-shadow: 0 4px 4px #00000025;
    width: 320px; height: 70vh;
    overflow-y: auto;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    transition: .5s;
    transform: translateX(-320px);

    @media $small-mobile {
      width: 65%;
    }

    + .main-section {
      width: 100%;
    }
  }
}

.mobile-filter-link {
  @extend .btn;
  border: 1px solid #97CDFA;
  border-radius: 5px;
  width: 170px;
  height: 47px;
  background-image: url(/i/filter-icon-blue.svg);
  background-repeat: no-repeat;
  background-position: 15px 50%;
  background-color: transparent;
  padding: 0 15px 0 50px;
  font-size: 20px;
  line-height: 25px;
  color: #000;
}

.filters-group-wrapper {
  display: none;

  @media $handheld {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background-color: #bfbfbf90;
  }
}

.filter-aside-title {
  float: left;
  width: fit-content;
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  margin: 10px 0 0 10px;
}

.filter-aside-close {
  @extend .btn;
  width: 94px;
  height: 24px;
  padding: 3px 10px 3px 8px;
  margin: 10px 20px 0 0;
  float: right;
  z-index: 10;
  border-radius: 15px;
  background: #97CDFA;
  color: $white;
  text-align: left;
  font-size: 14px;

  &:after {
    content: '';
    display: block;
    width: 14px; 
    height: 14px;
    margin-top: 1px;
    background: url(/i/close-no-border.svg) no-repeat;
    background-size: contain;
    float: right;
  }
}


// Collections

/* .collections {
  margin-top: 5px;

  &:not(.is-expanded) {
    margin-bottom: -72px;
  }
} */

.collections-section {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}

.collections--header,
.collections--footer {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.collections--header {
  @media $small-mobile {
    flex-direction: column;
    align-items: none;
  }
}

.collections--header.collections--header-inline{
  .collections--caption{
    display inline-block;
  }
  .collections--toggle-button{
    min-width 200px;
    margin-left 20px;
    margin-right 20px;

    @media $small-handheld{
      min-width 216px;
      margin-top: 25px;
    }

  }
  .collections--body-text{
    width calc(100% - 480px)
    min-width 200px;
    display inline-block;
  }
}

.collections--footer {
  /* margin-left: calc(18% + 10px); */
  margin-bottom: -42px;

  /* @media $notebook {
    margin-left: 260px;
  } */

  @media $landscape-mobile {
    /* margin-top: -40px; */
    margin-bottom: 10px;
    /* margin-left: 0; */
    justify-content: center;
  }

  @media $small-mobile {
    display: flex;
    flex-direction: column-reverse;

    button {
      margin-left: 0;
      margin-bottom: 20px;
    }
  }
}

.collections:not(.is-expanded) .collections--footer {
  display: none;
}

.collections--caption {
  font-size: 1.8rem;
  text-transform: uppercase;
}

.collections--toggle-button {
  @extend .btn, .black-btn, .rounded-btn, .h40btn;
  font-size: 1.5rem;
  padding: 0 25px;
  box-shadow: 0 4px 20px rgba(#000, .25);
  margin-left: 25px;
  position: relative;
  z-index: 10;

  span {
    display: inline-block;
    vertical-align: middle;
    margin-top: -8px;
    line-height: 1;
  }

  .collections.is-expanded &,
  .collections-section.is-expanded + main &,
  .collections-section.is-expanded + .catalogue-gallery + main & {
    .collapsed-text {
      display: none;
    }
  }

  .collections:not(.is-expanded) &,
  .collections-section:not(.is-expanded) + main &,
  .collections-section:not(.is-expanded) + .catalogue-gallery + main & {
    .expanded-text {
      display: none;
    }
  }
}


.collections--list {
  display: flex;
  flex-wrap: wrap;
  /* display: grid; */
  /* grid-template-columns: repeat(10, 1fr); */
  /* grid-column-gap: 20px; */
  /* grid-row-gap: 20px; */
  margin-bottom: 45px;
  padding-top: 10px;

  /* @media only screen and (max-width: 1460px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media $small-handheld {
    grid-template-columns: repeat(5, 1fr);
  } */

  @media $small-mobile {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1461px) {
    .item {
      flex-basis: calc(10% - 18px);
    }

    .item:not(:nth-child(10n + 1)) {
      margin-left: 20px;
    }
  }

  @media only screen and (max-width: 1460px) {
    .item {
      flex-basis: calc(12.5% - 14px);
    }

    @media $not-small-handheld {
      .item:not(:nth-child(8n + 1)) {
        margin-left: 16px;
      }
    }
  }

  @media $small-handheld {
    .item {
      flex-basis: calc(33.3% - 10px);
    }

    .item:not(:nth-child(3n + 1)) {
      margin-left: 15px;
    }
  }

  .item {
    text-align: center;
    /* flex-basis: calc(10% - 16px); */
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
    /* grid-column-end: span 1; */

    .item--links-box {
      display: flex;
      flex-direction: column;
    }
  }

  .item.is-active {
    box-shadow: 0 0 10px 2px rgba($blue, .75);
  }

  .item--image {
    display: block;
    margin-bottom: 7px;
    position: relative;

    &:before {
      content: '';
      display: block;
      padding-bottom: 95%;
    }

    img {
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
      object-fit: cover;
    }
  }

  .item--icons {
    position: absolute;
    left: 5px; top: 5px;
    @extend .product-icons-prototype;
  }

  .item--avail-icon {
    position: absolute;
    right: 0; top: 0;
    background: url(/i/avail-icon.svg) no-repeat;
    width: width('i/avail-icon.svg'); height: height('i/avail-icon.svg');
  }

  .item--name {
    font-size: 1.6rem;
  }

  .item--amount {
    font-size: 1.4rem;
    margin-top: 3px;
  }
}

.collections--pager {
  text-align: center;
  margin-bottom: 45px;
}

.collections_static {
  display: flex;
  align-items: flex-start;

  @media $small-handheld {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .collections--header,
  .collections--footer {
    text-align: center;
    display: block;

    @media $desktop {
      flex-basis: calc(20% - 10px);
    }

    @media $landscape-tablet {
      flex-basis: calc(25% - 10px);
    }

    @media $small-handheld {
      width: 100%;
    }
  }

  .collections--body {
    @media $not-small-handheld {
      margin-left: 20px;
    }

    @media $desktop {
      flex-basis: calc(80% - 10px);
    }

    @media $landscape-tablet {
      flex-basis: calc(75% - 10px);
    }

    @media $small-handheld {
      width: 100%;
    }
  }

  .collections--caption {
    display: block;
    font-size: 2.4rem;
    text-align: uppercase;
    margin-top: -5px;

    @media $mobile {
      font-size: 1.8rem;
      margin-top: 0;
    }
  }

  .collections--country {
    font-size: 1.4rem;
    color: $dark-gray;
    margin-top: 5px;
  }

  .item {
    /* @media $desktop {
      &:nth-child(5) ~ .item {
        margin-top: 40px;
      }

      &:nth-child(5n + 1) {
        margin-left: 0;
      }
    } */

    @media $tablet {
      flex-basis: calc(33.33% - 14px);

     /*  &:nth-child(3) ~ .item {
        margin-top: 40px;
      }

      &:nth-child(3n + 1) {
        margin-left: 0;
      } */
    }

    @media $mobile {
      flex-basis: calc(50% - 5px);

      + .item {
        margin-left: 10px;
      }

      /* &:nth-child(2) ~ .item {
        margin-top: 30px;
      }

      &:nth-child(2n + 1) {
        margin-left: 0;
      } */
    }
  }
}

.collections-expander {
  display flex;
  background #474343;
  color white;
  width fit-content;
  padding 10px 25px;
  border-radius 10px;
  cursor pointer;
  margin-top 10px;
  height fit-content;

  &:hover {
    background #696969;
  }

  &__wrap {
    display flex;
    justify-content center;
    position relative;
    background white;

    &--desktop-down {
      border-top 3px #97cdfa solid;
      margin-top -190px;
      height 110px;
    }

    &--desktop-up {
      margin-top -60px;
      margin-bottom 50px;
    }
  }

  &__icon {
    width: 20px;
    height 20px;
    margin-right 10px;
  }
}

.collections:not([style*="display: none"]) ~ .collections {
  border-top: 1px solid #828282;
  padding-top: 70px;
  margin-top: 10px;

  @media $mobile {
    padding-top: 40px;
    margin-top: 0;
  }
}


// Alphabet filter

.catalogue-page--sorting_wide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 55px;

  @media $less-than-wrapper {
    flex-direction: column;
  }

  @media $small-mobile {
    display: none;
    box-sizing: border-box;
    width: 100%; height: 100vh;
    z-index: 100;
    background: $white;
    padding: 35px 20px;
    text-align: center;
    position: fixed;
    left: 0; top: 0;
    overflow-y: auto;

    + .collections .collections--header,
    + .collections .collections--footer {
      width: calc(100% - 45px);
      padding-left: 45px;
    }

    .select-container + .select-container {
      margin: 10px;
    }
  }
}

.catalogue-page--alphabet-filter {
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  @media $less-than-wrapper {
    margin-bottom: 35px;
    justify-content: center;
  }

  .item {
    text-transform: uppercase;
    font-size: 1.8rem;
    color: #828282;
    cursor: pointer;

    &.is-active {
      color: #000;
    }
  }

  .item + .item {
    margin-left: 15px;
  }

  .item_all {
    display: none;
  }

  .item.is-active ~ .item_all {
    display: inline-block;
  }
}


.show-mobile-collections-sorting {
  @extend .btn;
  width: 47px; height: @width;
  background: $blue url(/i/filter-icon.svg) no-repeat 50%;
  border-radius: 50%;
  position: absolute;
  left: 0; top: -10px;
}

.collections-sorting-close {
  @extend .btn;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px; right: 15px;
  z-index: 10;
  cursor: pointer;
  background: transparent;
  transform: rotate(45deg);

  &:before, &:after {
    content: '';
    display: block;
    background: #000;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 21px; height: 1px;
  }

  &:after {
    width: 1px; height: 21px;
  }
}


.list-view_loading,
.list-view-loading {
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%; height: calc(100% + 10px);
    background: rgba($white, .85);
    position: absolute;
    left: 0; top: 0;
    z-index: 2000;
  }

  &:after {
    content: '';
    display: block;
    width: 110px; height: 110px;
    background: url(/i/ajax/oboima-loader.gif) no-repeat 50%;
    position: absolute;
    left: 50%; top: 150px;
    transform: translateX(-50%);
    z-index: 2000;
  }
}

.list-view_loading-bottom,
.list-view-loading-bottom {
  &:after {
    top: auto;
    bottom: 150px;
  }
}

.list-view_loading-center,
.list-view_loading-center {
  &:after {
    top: calc((100vh - 110px) / 2);
  }
}


.catalogue-page--filter-banner {
  margin-top: 50px;

  @media $handheld {
    display: none;
  }
}

.mobile-collections-selector {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 2em;

  .selectric {
    height: 35px;
    line-height: 35px;
  }

  .selectric .label {
    font-size: 1.4rem;
    height: 35px;
    line-height: 35px;
  }
}

.mobile-collections-selector--label {
  margin-bottom: .5em;
}


.lists-section {
  display: flex;
  align-items: flex-start;
  font-size: 1.6rem;
  line-height: 1.35;
  margin-bottom: 35px;

  li {
    list-style: none;
  }

  li + li {
    margin-top: .5em;
  }

  li a {
    text-decoration: none;

    &:hover {
      color: $blue;
    }
  }

  &--label-box {
    display flex;
    margin-bottom: 2em;
    height 40px;

    @media $small-mobile {
      flex-direction: column;
      margin-bottom: 80px;
    }

    .lists-section--label-item:first-child {
      margin-right: 30px;
      line-height: 2.3;

      @media $small-mobile {
        margin-bottom: 10px !important;
      }
    }
  }

  &--label-item {
    display: inline-block;
    margin-bottom: 0 !important;

    .is-hidden {
      visibility: hidden;
    }

    .filter {
      &--search {
        .inp {
          border-radius: 4px;
        }
      }

      &--group {
        min-width: 250px;
      }

      &--links-group {
        position: relative;
        background-color: white;
        z-index: 1;
        border: 1px solid #d9dfe7;
        border-radius: 4px;

        .item {
          margin-left: 10px;
          margin-right 10px;
        }

        .padding-top-item {
          padding-top: 12px;
        }

        .padding-bottom-item {
          padding-bottom: 10px;
        }
      }
    }
  }

  @media $landscape-mobile {
    flex-direction: column;
  }
}

.lists-section--caption {
  @extend .h3;
  text-transform: uppercase;
  color: $blue;
  margin-bottom: 2em;

  @media $mobile {
    margin-bottom: 1em;
  }
}

.lists-section--column {
  flex: 1;

  @media $mobile {
    flex-basis: 100%;
  }
}

.lists-section--column + .lists-section--column {
  @media $not-mobile {
    margin-left: 50px;
  }

  @media $mobile {
    margin-top: 2em;
  }
}

.lists-section--producers-list {
  flex: 1;
  padding: 0 50px 30px 0;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0; top: 0;
    z-index: -1;
    width: 100vw; height: 100%;
    box-shadow: 5px 5px 10px rgba(#000, .1);
  }

  @media $landscape-mobile {
    padding-right: 0;

    &:before {
      display: none;
    }
  }

  @media $mobile {
    padding-top: 0;
  }
}

.lists-section--body {
  display: flex;
  align-items: flex-start;

  @media $mobile {
    flex-wrap: wrap;
  }
}

.lists-section--letter {
  background: #d9dfe7;
  line-height: 41px;
  padding: 0 20px;
  font-size: 1.8rem;
  border-radius: 9px;
  margin-bottom: 1em;
}

.lists-section--group + .lists-section--group {
  margin-top: 2em;
}

.lists-section--patterns-list {
  margin-left: 45px;

  @media $landscape-mobile {
    margin-left: 0;
  }

  li {
    padding-left: 35px;
    background: url(/i/mark.svg) no-repeat;
  }
}


.catalogue-page--categories-section {
  margin-bottom: 35px;

  .catalogue-page--caption {
    margin-bottom: 1em;
  }
}

.catalogue-page--categories-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media $small-mobile {
    justify-content: space-between;
  }

  .item {
    text-align: center;
    text-decoration: none;
    margin-bottom: 1em;

    &:hover {
      color: $blue;
    }

    @media $desktop {
      flex-basis: calc(12.5% - 11px);

      &:not(:nth-child(8n + 1)) {
        margin-left: 12px;
      }
    }

    @media $handheld {
      flex-basis: calc(25% - 21px);

      &:not(:nth-child(4n + 1)) {
        margin-left: 28px;
      }
    }

    @media $small-mobile {
      flex-basis: calc(50% - 15px);
      margin-left: 0 !important;
    }
  }
}

.lists-section--patterns-list_half-page {
  flex: 1;
  margin-left: 0;
}

.lists-section--patterns-list_half-page + .lists-section--patterns-list_half-page {
  margin-left: 30px;

  @media $landscape-mobile {
    margin-left: 0;
    margin-top: 1em;
  }
}

.catalogue-gallery {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

.catalogue-gallery__caption {
  font-size: 1.8rem;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media $small-mobile {
    font-size: 1.4rem;
  }
}

.catalogue-gallery__list {
  overflow: hidden;
  white-space: nowrap;

  .item {
    display: inline-block;
    position: relative;
    width: 8%;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    img {
      width: 100%; height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0; top: 0;
    }
  }

  .slick-slide .item {
    width: auto;
  }

  .slick-list {
    margin-left: -10px;
  }

  .slick-slide {
    margin-left: 10px;
  }

  .slick-arrow {
    @extend .btn;
    width: 30px; height: @width;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid #bdbdbd;
    background: $white;
    position: absolute;
    top: 50%;
    z-index: 100;
    transform: translateY(-50%);
    font-size: 0;

    &:active:not(.slick-disabled) {
      margin-top: 2px;
    }

    &:before {
      content: '';
      display: block;
      width: 8px; height: 8px;
      box-sizing: border-box;
      border-top: 2px solid #bdbdbd;
      position: absolute;
      top: 50%;
      margin-top: -1px;
    }
  }

  .slick-disabled {
    opacity: .5;
    cursor: default;
  }

  .slick-prev {
    left: 5px;

    &:before {
      left: 50%;
      border-left: 2px solid #bdbdbd;
      transform: rotate(-45deg) translateY(-50%);
    }
  }

  .slick-next {
    right: 5px;

    &:before {
      right: 50%;
      border-right: 2px solid #bdbdbd;
      transform: rotate(45deg) translateY(-50%);
    }
  }
}

.filters-grouped {
  &__wrap {
    margin-bottom: 20px;
    display flex;

    @media $small-mobile {
      padding: 0 15px;
    }
  }

  &__label {
    margin-right 15px;

    &--text {
      margin-top 9px;
      font-size 2rem;

      @media $small-mobile {
        font-size 1.6rem;
        margin-top 5px;
      }
    }
  }

  &__box {
    &--mobile {
      width: 80%;

      .only-small-mobile {
        .mobile-collections-selector{
          margin-bottom 1em;
        }
      }
    }
  }


  &__list {
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    border-radius: 30px;
    color: white;
    background: $blue;
    transition: all .25s ease;
    flex: 1 1 0;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    display: block;
    margin-bottom: 10px;
    font-size 1.8rem;
    line-height 1;
    max-width: fit-content;

    @media $small-mobile {
      font-size 1.4rem;
    }

    &:hover {
      background $dark-blue;
      transition: all .25s ease;
    }

    a {
      display: block;
      padding: 15px 15px 15px 15px;
      text-decoration: none;
      color: inherit;
      white-space: nowrap;

      @media $small-mobile {
        padding: 10px 10px 10px 10px;
      }
    }
  }
}

.fixed-filter {
  position: fixed;
  top: 95px;
  background: white;
  z-index: 1000;
  width: 100%;
  padding-left: 17px;
}

.collections--toggle-button-additional-wrap{
  text-align: center;
  display: none;

  .collections--toggle-button{
    margin-left: 0;
  }

  @media $small-mobile {
    display: block;
    margin-bottom 30px;
  }
}

.attention-about-large-buy {
  display: none;
  color: red;
  padding: 5px;
  font-size: 14px;
  text-transform: none;
}